exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-scores-js": () => import("./../../../src/pages/scores.js" /* webpackChunkName: "component---src-pages-scores-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-teams-contentful-team-slug-js": () => import("./../../../src/pages/teams/{ContentfulTeam.slug}.js" /* webpackChunkName: "component---src-pages-teams-contentful-team-slug-js" */),
  "component---src-pages-teams-contentful-team-slug-retired-js": () => import("./../../../src/pages/teams/{ContentfulTeam.slug}/retired.js" /* webpackChunkName: "component---src-pages-teams-contentful-team-slug-retired-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

